.chq-atc {
  display: inline-block;
  position: relative;
}

.chq-atc--button {
  background: transparent;
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  color: #6a89af;
  cursor: pointer;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  outline: none;
  padding: 4px 10px;
}

.chq-atc--button:focus {
  background-color: #eaeaea;
}

.chq-atc--button:hover {
  background-color: #eaeaea;
}

.chq-atc--button svg {
  vertical-align: text-bottom;
}

.chq-atc--button path {
  fill: #6a89af;
}

.chq-atc--dropdown {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.chq-atc--dropdown a {
  color: #6a89af;
  display: block;
  padding: 8px 15px;
  text-decoration: none;
}

.chq-atc--dropdown a:hover {
  background-color: #eaeaea;
}
